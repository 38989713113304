import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Renovierung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Renovierung – welche Finanzierungsmöglichkeiten gibt es?" showCalc={false} />
            <Article>
                <p>
                    Nicht nur für den Haus- oder Wohnungskauf, sondern auch im Falle von Sanierungs- oder
                    Renovierungsprojekten ist es meist nötig, einen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    aufzunehmen - schon ein solche Instandsetzung ist schließlich teuer genug. Welche
                    Finanzierungsmöglichkeiten bei der Renovierung deiner Immobilie zur Auswahl stehen, ist Gegenstand
                    des folgenden Artikels.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Renovierung oder Umbau?</h2>
                <p>
                    Zunächst sollten wir uns ansehen, ob dein Vorhaben tatsächlich der baurechtlichen Definition einer
                    Renovierung entspricht. Das ist nicht unwichtig für das Beratungsgespräch mit deiner Bank. Denn je
                    nach Projektart fallen auch unterschiedliche Kosten an.
                </p>
                <p>
                    Bei der meist im urbanen Raum durchgeführten Altbausanierung etwa geht es insbesondere um die
                    Sicherung einer erhöhten Energieeffizienz, weshalb man vor allem bei Fenstern, Türen und der
                    Heizungsanlage ansetzt. Sanierst du deinen Altbau noch gründlicher, so näherst du dich dem
                    Tatbestand einer Kernsanierung. Diese umfasst eine grundsätzliche Erneuerung von Fasse, Dach, Keller
                    und anderer Bereiche. Einen anders gearteten Bereich bilden dagegen Anbau und Aufstockung. Diese
                    meinen die Erweiterung eines vorhandenen Objekts beispielsweise um zusätzliche Wohnräume oder aber
                    um Zusätze wie einen Balkon, eine Terrasse, eine Garage, ein Vordach oder einen Wintergarten. Die
                    Renovierung schließlich, um deren Finanzierung es hier im Weiteren ausschließlich gehen soll,
                    bezieht sich auf die Wiederherstellung des ursprünglichen Zustands einer Immobilie. Dies kann zum
                    Beispiel durch einen Neuanstrich im Innen- oder Außenbereich und/oder durch neu verlegte Böden
                    passieren.
                </p>
                <hr />

                <h2>Welche Finanzierungsmöglichkeiten für Renovierungen gibt es?</h2>
                <p>
                    Ein kleiner Tipp zum Einstieg: Wenn du erst einmal ein Gefühl dafür entwickeln möchtest, welche
                    Auswirkungen die einzelnen Größen deines Kredites (allen voran Summe, Laufzeit und Raten)
                    aufeinander haben, dann ist unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    genau das Richtige!
                </p>
                <p>Folgende Finanzierungsmöglichkeiten stehen dir für deine Renovierung zur Verfügung:</p>
                <ul>
                    <li>
                        <strong>Rücklagen:</strong> Als Eigentümer:in eines Hauses bzw. einer Wohnung ist es allgemein
                        empfehlenswert, gleich nach dem Kauf mit dem Bilden von Rücklagen zu beginnen. Auf diese kann
                        dann nicht nur im Schadensfall, sondern auch im Zuge einer Renovierung zurückgegriffen werden
                        bzw. kann das Ersparte als Eigenkapital in einen neuen Kredit eingebracht werden.
                    </li>
                    <li>
                        <strong>Privatdarlehen:</strong> Gerade wenn man für die Renovierungsfinanzierung
                        vergleichsweise kleine Geldbeträge benötigt, für die es aufgrund der veranschlagten{" "}
                        <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                            Zinsen
                        </Link>{" "}
                        nicht lohnen würde, eigens einen Kredit aufzunehmen, kann ein Darlehen von Freund:innen oder
                        Verwandten Abhilfe schaffen. Um keine Missverständnisse oder gar Konflikte aufkommen zu lassen,
                        ist es allerdings ratsam, auch im Kontext solcher privater Leihgeschäfte einen{" "}
                        <Link to="/artikel/kreditvertrag/" target="_blank" rel="noreferrer noopener">
                            Kreditvertrag
                        </Link>{" "}
                        aufzusetzen zu lassen. Alternativ können die Beteiligten die entsprechenden{" "}
                        <Link to="/artikel/konditionen/" target="_blank" rel="noreferrer noopener">
                            Konditionen
                        </Link>{" "}
                        auch selbst in Schriftform festhalten und von der jeweils anderen Partei gegenzeichnen lassen.
                    </li>
                    <li>
                        <strong>Umschuldung eines laufenden Kredits:</strong> Eine weitere Möglichkeit, deine
                        Renovierung zu finanzieren, bildet die{" "}
                        <Link to="/dienstleistungen/umschuldung/" target="_blank" rel="noreferrer noopener">
                            Umschuldung
                        </Link>{" "}
                        einer bereits laufenden Finanzierung. Dabei wird ein bestehender Kredit - beispielsweise jener,
                        der beim Kauf des zu sanierenden Objekts aufgenommen wurde - mit heute ungünstigen Konditionen
                        durch einen oder mehrere andere ersetzt, um in Summe bessere Konditionen zu erzielen. Die dabei
                        eingesparten Kosten kannst du für die Finanzierung des Renovierungsvorhabens einsetzen.
                    </li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"renovierung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"renovierung"}
                heading={"Renovierung – welche Finanzierungsmöglichkeiten gibt es?"}
            />
        </Layout>
    );
};

export default Renovierung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.artikel.renovierung"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
